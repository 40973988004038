import axios from "axios"
import { USER_DATA_KEY } from "./constants"

abstract class BaseClient {
  constructor(private readonly baseUrl: string) {}

  async get(url: string, query: {} = {}) {
    const absoluteUrl = this.getAbsoluteUrl(url)
    for (const [k, v] of Object.entries(query)) {
      absoluteUrl.searchParams.set(k, v as any)
    }

    const response = await axios.get(absoluteUrl.toString(), {
      headers: this.getAuthHeaders()
    })
    if (response.data) {
      return response.data
    }
  }

  async post(url: string, body: {} | null, query: {} = {}) {
    const absoluteUrl = this.getAbsoluteUrl(url)
    for (const [k, v] of Object.entries(query)) {
      absoluteUrl.searchParams.set(k, v as any)
    }

    let headers = this.getAuthHeaders()
    if (body != null) {
      headers = {
        'Content-Type': 'application/json',
        ...headers
      }
    }
    const response = await axios.post(absoluteUrl.toString(), body, {
      headers: headers
    })
    if (response.data) {
      return response.data
    }
  }

  protected abstract getAuthHeaders(): Record<string, string>

  private getAbsoluteUrl(url: string) {
    return new URL(this.baseUrl + url)
  }
}

export class AnonymousInvestorPlatformClient extends BaseClient {
  async register(email: string, password: string, isInvestor: boolean) {
    await this.post('/users/register', {
      email,
      password,
      isInvestor
    })
  }

  async confirmRegistration(token: string) {
    await this.post('/users/confirmRegistration', null, { token })
  }

  async startResetPassword(email: string) {
    await this.post('/users/startResetPassword', { email })
  }

  async resetPassword(token: string, newPassword: string) {
    await this.post('/users/resetPassword', {
      token: token,
      newPassword: newPassword
    })
  }

  protected getAuthHeaders() {
    return {}
  }
}

export class InvestorPlatformClient extends BaseClient {
  private token: string | null = null
  private userId: number | null = null

  constructor(
    baseUrl: string,
    userId: number | null = null,
    token: string | null = null
  ) {
    super(baseUrl)
    this.userId = userId
    this.token = token
  }

  async login(email: string, password: string) {
    const { userId, token, requiredTos } = await this.post('/users/login', { email, password })
    this.userId = userId
    this.token = token
    return requiredTos
  }

  saveToken() {
    if (!this.token) {
      throw new Error('client is not logged in')
    }

    localStorage.setItem(USER_DATA_KEY, JSON.stringify({
      userId: this.userId,
      token: this.token
    }))
  }

  protected getAuthHeaders() {
    if (!this.token) {
      return {} as any
    }

    return {
      'Authorization': `Bearer ${this.token}`
    }
  }

  static getFromStoredToken(baseUrl: string): InvestorPlatformClient {
    const serializedData = localStorage.getItem(USER_DATA_KEY)
    if (!serializedData) {
      throw new Error('no token in localStorage')
    }

    const { userId, token } = JSON.parse(serializedData)
    return new InvestorPlatformClient(baseUrl, userId, token)
  }

  async getCurrentUser(): Promise<User> {
    return await this.get('/users/me')
  }

  async updateUser(userUpdate: UserUpdate) {
    return await this.post(`/users/${this.userId}`, userUpdate)
  }

  async getChains(): Promise<Chain[]> {
    return await this.get(`/users/${this.userId}/chains`)
  }

  async setSubscription(traderId: number, subscribed: boolean) {
    return await this.post(`/users/${traderId}/setSubscription`, null, {
      subscribed
    })
  }

  async getWalletByChainId(chainId: number) {
    return await this.get(`/users/${this.userId}/wallets/chain/${chainId}`)    
  }

  async getAllHoldings(): Promise<Holding[]> {
    return await this.get(`/users/${this.userId}/wallets/allHoldings`)
  }

  async withdraw(walletId: number, holdingId: number, targetAddress: string, amount: string) {
    return await this.post(`/users/${this.userId}/wallets/${walletId}/withdraw`, {
      holdingId: holdingId,
      targetAddress: targetAddress,
      amount: amount
    })
  }

  async executeTrade(orderCreate: OrderCreate): Promise<Order> {
    return await this.post(`/users/executeTrade`, orderCreate)
  }

  async getOrder(orderId: number): Promise<Order> {
    return await this.get(`/users/${this.userId}/orders/${orderId}`)
  }

  async getCostBasis(): Promise<{ depositCostBasis: number, withdrawalCostBasis: number}> {
    return await this.get(`/users/${this.userId}/costBasis`)
  }

  async getTxHistories(
    page: number,
    maxId: number
  ): Promise<{ value: TxHistory[], totalItems: number }> {
    return await this.get(`/users/${this.userId}/txHistories`, { page, maxId })
  }

  async acceptTos(tosVersion: number) {
    return await this.post(`/users/${this.userId}/acceptTos`, null, { tosVersion })
  }

  async getEtherPrice(chainId: number) {
    return await this.get(`/users/${this.userId}/etherPrice`, { chainId })
  }
}

export type ChainConfig = {
  id: number
  chain_id: number
  is_enabled: boolean
  is_manual: boolean
  gas_limit: string | null
  small_trade_amount: string
  medium_trade_amount: string
  large_trade_amount: string
  is_high_gas_enabled: boolean
  is_small_trade_enabled: boolean
  is_medium_trade_enabled: boolean
  is_large_trade_enabled: boolean
}

export type ChainConfigUpdate = {
  chainId: number
  isEnabled: boolean
  isManual: boolean
  gasLimit: string | null
  smallTradeAmount: string
  mediumTradeAmount: string
  largeTradeAmount: string
}

export type UserSubscription = {
  subscriber_id: number
  subscribee_id: number
}

export enum TradeSizes {
  None = 0,
  Small = 1,
  Medium = 2,
  Large = 4
}

export type User = {
  id: number
  email: string
  is_email_confirmed: boolean
  display_name: string
  is_trading_enabled: boolean
  trade_sizes_enabled: TradeSizes
  is_investor: boolean
  is_trader_approved: false
  chain_configs?: ChainConfig[],
  source_user_subscriptions?: UserSubscription[]
}

export type UserUpdate = {
  isTradingEnabled: boolean
  tradeSizesEnabled: TradeSizes
  chainConfigs: ChainConfigUpdate[]
}

export enum OrderStatus {
  Open = 0,
  Filled = 1,
  Failed = 2
}

export type Order = {
  id: number
  transaction_hash?: string
  status: OrderStatus
  error_reason: string
}

export enum OrderType {
  Solo = 0,
  Shared = 1,
  Other = 2
}

export enum OrderAction {
  Buy = 0,
  Sell = 1
}

export enum SizeCategory {
  Small = 0,
  Medium = 1,
  Large = 2
}

export type OrderCreate = {
  chainId: number
  dexRouterAddress: string
  tokenContractAddress?: string
  orderType: OrderType
  orderAction: OrderAction
  holdingId?: number
  sizePercentNumerator?: number
  sizePercentDenominator?: number
  sizeCategory?: SizeCategory
  absoluteSize?: string
  slippagePercentNumerator: number
  slippagePercentDenominator: number
}

export type Holding = {
  id: number
  contract_address: string
  name: string
  symbol: string
  balance: string
  decimals: number
  dex?: string
  wallet_id: number
  chain_id: number
  order_type: OrderType
}

export type Chain = {
  chainId: number
  name: string
  abbreviation: string
  currencyName: string
  currencySymbol: string
}

export enum TransactionType {
  Deposit = 1,
  Withdraw = 2,
  Swap = 3
}

export enum TransactionStatus {
  Pending = 0,
  Complete = 1,
  Failed = 2
}

export type TxHistory = {
  id: number
  transaction_type: TransactionType
  ts: Date,
  status: TransactionStatus
  transaction_hash?: string
  value_usd?: number
  amount?: string
  token_address?: string
  target_token_address?: string
  target_amount?: string
  completed_ts?: Date
  error_reason?: string
  order_type?: OrderType
  order_action?: OrderAction
  is_parent?: boolean
  is_child?: boolean
  chain_id: number
}